<template>
    <div>
        <!-- 头部 -->
        <!-- 头部 -->
        <div class="file-browser-header">
            <el-button @click="newPage()" class="action-button" icon="el-icon-refresh-right"></el-button>
            <el-input v-model="params.fileSortName" class="search-input" placeholder="请输入文件夹名称"></el-input>
            <el-button type="primary" @click="findBySearch()">搜索</el-button>
            <el-button type="success" @click="newFolder()">新建文件夹</el-button>
            <el-button type="warning" @click="uploadFile()">上传文件</el-button>
        </div>

        <!-- 表单主题内容 -->
        <div class="file-browser">
            <div class="table-container">
                <el-row>
                    <el-col :span="4" v-for="item in tableData" :key="item.id">
                        <div class="folder-card">
                            <div @click="clickFile(item)" class="clickable-icon"
                                @contextmenu.prevent="rightClickFile(item, $event)">
                                <img class="icon-item" :src="require(`@/assets/icon/file_yellow.svg`)" alt="文件图标" />
                            </div>
                            <div class="file-name" @dblclick="clickFileName(item)">
                                {{ item.fileSortName }}
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>

        <!-- 新建、修改（文件夹名称）弹框 -->
        <div>
            <el-dialog title="文件夹" :visible.sync="openFolderDialogFormVisible" width="30%">
                <el-form :model="form">

                    <el-form-item label="文件夹名称" label-width="20%">
                        <el-input v-model="form.fileSortName" autocomplete="off" style="width: 90%;"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="openFolderDialogFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="addNewFolder">确 定</el-button>
                </div>
            </el-dialog>
        </div>

        <!-- 点击文件夹，查看数据的 弹框 -->
        <div>
            <el-dialog title="文件夹" :visible.sync="openFolderInfoDialogFormVisible" width="90%"
                v-if="openFolderInfoDialogFormVisible" append-to-body>
                <File :folder-id="selectedFolderId" />
            </el-dialog>
        </div>


        <!-- 点击上传文件， 弹框 -->
        <div>
            <UploadFile v-if="openUploadFileDialogFormVisible" append-to-body
                @close-dialog="openUploadFileDialogFormVisible = false" />
        </div>


        <!-- 右击文件夹，查看数据的 弹框 -->
        <div>
            <el-dialog title="文件夹属性" :visible.sync="openRightClickFileDialogFormVisible" width="30%">
                <el-form :model="folderTotalInfo" label-position="left" label-width="100px">
                    <el-form-item label="文件夹名称：">
                        <span>{{ folderTotalInfo.fileSortName }}</span>
                    </el-form-item>
                    <el-form-item label="文件数量：">
                        <span>{{ folderTotalInfo.totalFolder }}</span>
                    </el-form-item>
                    <el-form-item label="文件夹大小：">
                        <span>{{ folderTotalInfo.returnSize }}</span>
                    </el-form-item>
                    <el-form-item label="创建时间：">
                        <span>{{ folderTotalInfo.creatTime }}</span>
                    </el-form-item>
                    <!-- 添加按钮容器 -->
                    <div style="text-align: right; margin-top: 20px;">
                        <el-button type="danger" @click="confirmDelete(folderTotalInfo.id)">删除文件夹</el-button>
                    </div>

                </el-form>
            </el-dialog>
        </div>

    </div>
</template>

<script>
import request from '@/utils/request'
import File from '@/components/FrontFileView.vue'
import UploadFile from '@/components/FrontUploadFile.vue'

export default {
    components: {
        File, UploadFile
    },

    data() {
        return {
            // 双条件查询
            params: {
                fileSortName: '' // 文件夹名称
            },
            //表单主体内容
            tableData: [],
            openFolderDialogFormVisible: false,  //文件夹弹窗
            openFolderInfoDialogFormVisible: false,  //点击文件夹 ‘内容’ 弹窗
            openUploadFileDialogFormVisible: false,  //文件上传 弹窗
            openRightClickFileDialogFormVisible: false,  //右击文件夹 弹窗
            //表单属性
            form: {
                fileSortName: '',
            },
            user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {},
            fileInfoParams: {
                fileName: "", //文件名称
                fileSortId: "", //文件夹id
                pageNum: 1, //当前页码
                pageSize: 5 //一页多少数据
            },
            selectedFolderId: null, //选中的文件夹id
            folderTotalInfo: [],
        }
    },
    //一进入页面，就执行的代码
    created() {
        this.findBySearch()
    },
    methods: {
        //右击文件夹（获取文件夹总信息）
        rightClickFile(item, event) {
            this.openRightClickFileDialogFormVisible = true; //打开右击弹窗
            request.get("/folder/findFolderTotalInfo/" + item.id).then(res => {
                if (res.code === '200') {
                    this.folderTotalInfo = res.data
                    console.log('--> ', this.folderTotalInfo)
                } else {
                    this.$message.error(res.msg);
                    console.log(' NOT Msg ·····')
                }
            })
        },
        // 查询所有文件夹（文件名称模糊查询）
        findBySearch() {
            request.get("/folder/allInfo", {
                params: this.params
            }).then(res => {
                if (res.code === '200') {
                    this.tableData = res.data
                } else {
                    this.$message.error(res.msg);
                    console.log(' NOT Msg ·····')
                }
            })
        },
        // 刷新输入框
        newPage() {
            this.params.fileSortName = '';
            this.findBySearch();
        },
        // 新建文件夹（打开弹窗）
        newFolder() {
            this.form = {};
            this.openFolderDialogFormVisible = true;
        },
        //添加新文件夹（实际功能）
        addNewFolder() {
            this.openFolderDialogFormVisible = false;
            this.canEdit = false;
            request.post("/folder/", this.form).then(res => {
                if (res.code === '200') {
                    this.findBySearch() //刷新页面
                    this.$message.success("操作成功");
                } else {
                    console.log(' NOT Msg ·····')
                    this.$message.error(res.msg);
                }
            })
        },
        //双击-打开（修改文件夹的 (弹窗）
        clickFileName(row) {
            this.openFolderDialogFormVisible = true;
            this.form = row;
        },
        //点击文件，打开文件夹内容的（弹窗）
        clickFile(row) {
            this.$nextTick(() => {
                this.selectedFolderId = row.id; // 设置需要传递给子组件的 ID
                this.openFolderInfoDialogFormVisible = true;
            });
        },
        //上传文件(弹窗)
        uploadFile() {
            this.openUploadFileDialogFormVisible = true;
        },
        //删除文件（弹窗）
        confirmDelete(id) {
            this.$confirm('确定要删除这个文件夹吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                // 用户点击确定后执行删除操作
                this.deleteFolder(id);
            }).catch(() => {
                // 用户点击取消
                this.$message.info('已取消');
            });
        },
        //删除文件夹（最终逻辑）
        deleteFolder(id) {
            request.delete("/folder/" + id).then(res => {
                if (res.code === '200') {
                    this.findBySearch() //刷新页面
                    this.$message.success("删除成功");
                    this.openRightClickFileDialogFormVisible = false; //关闭右击弹窗
                } else {
                    console.log(' NOT Msg ·····')
                    this.$message.error(res.msg);
                }
            })
        },
    },
}
</script>

<style scoped>
/* ------------------------头部---------------------- */
.file-browser-header {
    display: flex;
    align-items: center;
    margin: 10px 0;
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.action-button {
    background-color: transparent;
    border: none;
    color: #606266;
    padding: 5px;
    margin-right: 10px;
    cursor: pointer;
    transition: color 0.3s ease;
}

.action-button:hover {
    color: #409eff;
}

.search-input {
    margin-right: 10px;
    width: 200px;
}

/* 设置按钮的样式 */
.el-button {
    border-radius: 4px;
    padding: 10px 20px;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.el-button:hover {
    transform: scale(1.05);
}

/* 调整按钮颜色 */
.el-button--primary {
    background-color: #409eff;
    border-color: #409eff;
}

.el-button--success {
    background-color: #67c23a;
    border-color: #67c23a;
}

.el-button--warning {
    background-color: #e6a23c;
    border-color: #e6a23c;
}

/* 按钮文字颜色 */
.el-button--primary,
.el-button--success,
.el-button--warning {
    color: #ffffff;
}

/* 输入框样式 */
.el-input__inner {
    border-radius: 4px;
    height: 34px;
    line-height: 34px;
}


/* ------------------------- 主体 -----------------------*/
.file-browser {
    padding: 40px;
    /* 增加容器内边距 */
    background-color: #f5f5f5;
    /* 添加背景颜色 */
    border-radius: 20px;
    /* 添加圆角边框 */
}

.table-container {
    background-color: #ffffff;
    /* 表格容器背景颜色 */
    border-radius: 10px;
    /* 添加圆角边框 */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* 添加阴影效果 */
    padding: 20px;
    /* 容器内边距 */
}

.folder-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* 调整底部边距 */
    margin-bottom: 50px;
    /* 鼠标悬停动画效果 */
    transition: transform 0.2s ease;
}

.folder-card:hover {
    transform: scale(1.1);
    /* 鼠标悬停时的放大效果 */
}

.clickable-icon {
    cursor: pointer;
    /* padding: 11px; */
    /* 增加点击区域的尺寸 */
    /* background-color: #ebebeb; */
    /* 图标背景颜色 */
    /* border-radius: 8px; */
    /* 添加圆角边框 */
    /* margin-bottom: 10px; */
    /* 图标与文件名间距 */
    /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); */
    /* 添加阴影效果 */
}

.icon-item {
    width: 120px;
    /* 调整图标大小 */
    height: auto;
    /* 保持图标的长宽比 */
}

.file-name {
    cursor: pointer;
    font-size: 14px;
    /* 文件名字体大小 */
    color: #333333;
    /* 文件名字体颜色 */
    text-align: center;
    word-break: break-all;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    /* 确保容器有明确的宽度 */
    max-width: 50%;
    font-weight: bolder;
    /* 字体加粗 */

}

/* -------------------------- （右键文件夹信息）弹窗 ---------- */
/* 添加一些自定义样式来美化表单 */
.el-form-item {
    margin-bottom: 10px;
}

/* 突出标题 */
.el-dialog__title {
    font-weight: bold;
    color: #303133;
    /* 或者你喜欢的任何颜色 */
}

/* 调整标签样式 */
.el-form-item__label {
    font-weight: bold;
    /* 使标签加粗 */
    color: #606266;
    /* 标签颜色 */
}

/* 调整内容文字样式 */
.el-form-item__content span {
    color: #909399;
    /* 文字颜色 */
}
</style>