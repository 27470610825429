<template>
  <div style="height: 100%;">
    <el-container style="height: 100%;">

      <!-- 侧边 -->
      <el-aside style="overflow: hidden; min-height: 100vh; background-color: #f0f2f5; width: 250px">

        <el-menu class="menu_styleaa" :default-active="$route.path" router background-color="#f0f2f5" text-color="black"
          active-text-color="#409eff">

          <el-menu-item index="/admin/homeView">
            <i class="el-icon-s-home"></i>
            <span slot="title">系统首页</span>
          </el-menu-item>

          <el-submenu index="2">
            <template slot="title">
              <i class="el-icon-folder-opened"></i><span>文件夹</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/admin/folder">文件夹信息</el-menu-item>
            </el-menu-item-group>
          </el-submenu>

          <el-submenu index="3">
            <template slot="title">
              <i class="el-icon-document"></i><span>文件</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/admin/file">文件信息</el-menu-item>
            </el-menu-item-group>
          </el-submenu>


        </el-menu>

      </el-aside>

      <!-- 中间 -->
      <el-container>

        <!-- 中间：头部 -->
        <el-header style="text-align: right; font-size: 12px">
          <el-dropdown style="float: right; margin-right: 20px">
            <i class="el-icon-setting" style="margin-right: 15px"></i>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="editUserInfo">修改个人信息</el-dropdown-item>
              <el-dropdown-item @click.native="logout">退出</el-dropdown-item>
              <el-dropdown-item @click.native="toIndex">前往首页</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <span style="margin-right: 20px; font-size: 16px; color: #333">{{ userInfo }}</span>
        </el-header>

        <!-- 中间：主体 -->
        <el-main>
          <!-- 使用路由的跳转，把队员组件，显示在这个main部分 -->
          <router-view></router-view>
        </el-main>

      </el-container>

    </el-container>

  </div>
</template>

<script>
export default {
  data() {
    return {
      userInfo: "" // 用户信息
    }
  },
  created() {
    var localStorageUserInfo = localStorage.getItem('userInfo') // 获取本地存储的用户信息
    this.userInfo = JSON.parse(localStorageUserInfo) // 解析本地存储的用户信息
    console.log(this.userInfo)
    this.userInfo = this.userInfo.userName || "ChenXiXi" // 如果本地存储的用户信息为空，则给userInfo赋一个空对象
  },
  methods: {
    // 退出登录
    logout() {
      this.$confirm('确认退出？')
        .then(() => {
           //删除本地存储的用户信息
           if(localStorage.getItem('userInfo')){
             localStorage.removeItem('userInfo')
           }
          this.$router.push('/login');
        })
        .catch(() => {
          this.$message.info('取消退出');
        });
    },
    // 编辑个人信息
    editUserInfo() {
      this.$router.push('/editUser')
    },
    // 前往首页
    toIndex() {
      this.$router.push('/frontDesk/folderPage')
    }
  }
};
</script>

<style scoped>
.el-header {
  background-color: #B3C0D1;
  color: #333;
  line-height: 60px;
}

.el-asidea {
  color: #333;
  border-right: 0;
}

.menu_styleaa {
  min-width: 0;
  border: 0;
}
</style>
