<template>
    <div class="app-container">
        <el-row>
            <el-col :span="24">
                <div>
                    <tiny-calendar-view :events="eventslist" :year="currentYear" :month="currentMonth" mode="month"
                        :modes="[]" :timeFormat="'YYYY-MM-DD HH:mm'">
                        <!-- 工具栏区 -->
                        <template #tool>
                            <div style="">
                                <el-button type="text" @click="backReminder">编辑 “提醒事项”</el-button>
                            </div>
                        </template>
                    </tiny-calendar-view>
                </div>
            </el-col>
        </el-row>

    </div>
</template>

<script>
import request from '@/utils/request'
import { CalendarView as TinyCalendarView } from '@opentiny/vue'
export default {
    name: "ReminderView",
    components: {
        TinyCalendarView
    },
    computed: {
        // 获取当前年份
        currentYear() {
            return new Date().getFullYear();
        },
        // 获取当前月份（JavaScript中月份是从0开始的，所以需要+1）
        currentMonth() {
            return new Date().getMonth() + 1;
        }
    },
    data() {
        return {
            params: {},
            eventslist: []
        };
    },
    created() {
        this.findBySearch();
    },
    methods: {
        /** 查询提醒事项列表 */
        findBySearch() {
            request.get("/reminder").then(res => {
                if (res.code === '200') {
                    this.eventslist = res.data //初始化的时候，查询总信息数
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        backReminder() {
            this.$router.push('/frontDesk/reminder');
        }
    }
};
</script>