<template>
    <div class="reminder-body">
        <!-- 这个是input输入框 -->
        <div style="margin:5px 0 18px 0;">

            <el-button @click="refresh()" type="info" icon="el-icon-refresh-right" size="small"
                style="margin-right: 10px" circle>

            </el-button> <el-input v-model="params.title" size="small" style="width: 200px; margin-right: 10px"
                placeholder="请输入事项名称">
            </el-input>


            <el-button type="warning" size="small" style="margin-left:8px" @click="findBySearch()">搜索</el-button>
            <el-button type="primary" size="small" @click="addButton()">添加</el-button>
            <el-button type="success" size="small" @click="openView()">打开可视化</el-button>

        </div>


        <!-- 这个是主体内容 -->
        <div>
            <h1>提醒事项</h1>
            <el-table :data="tableData" style="width: 100%" height="561">

                <el-table-column fixed prop="title" label="事项名称"></el-table-column>

                <el-table-column prop="start" label="开始时间"></el-table-column>

                <el-table-column prop="end" label="结束时间"></el-table-column>

                <el-table-column prop="content" label="事项内容"></el-table-column>

                <el-table-column prop="theme" label="事项背景颜色"></el-table-column>

                <el-table-column label="操作">

                    <template slot-scope="scope">
                        <el-button type="primary" size="small" @click="edit(scope.row)">编辑</el-button>
                        <el-popconfirm title="确定删除吗？" @confirm="deleteReminder(scope.row.id)">
                            <el-button slot="reference" size="small" type="danger"
                                style="margin-left: 5px;">删除</el-button>
                        </el-popconfirm>
                    </template>

                </el-table-column>

            </el-table>

        </div>

        <!-- 分页条 -->
        <div style="text-align: center; margin: auto;">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="params.pageNum" :page-sizes="[9, 20, 25, 30]" :page-size="params.pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </div>

        <!-- 弹框 -->
        <el-dialog title="提醒事项" :visible.sync="dialogFormVisible" width="500px" append-to-body>
            <el-form ref="form" :model="form" :rules="rules" label-width="80px">
                <el-form-item label="事项标题" prop="title">
                    <el-input v-model="form.title" placeholder="请输入事项标题" />
                </el-form-item>
                <el-form-item label="开始时间" prop="start">
                    <el-date-picker clearable v-model="form.start" type="datetime" value-format="yyyy-MM-dd HH:mm"
                        format="yyyy-MM-dd HH:mm" placeholder="请选择开始时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间" prop="end">
                    <el-date-picker clearable v-model="form.end" type="datetime" value-format="yyyy-MM-dd HH:mm"
                        format="yyyy-MM-dd HH:mm" placeholder="请选择结束时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="背景颜色" prop="theme">
                    <el-select v-model="form.theme" placeholder="请选择">
                        <el-option v-for="item in themeList" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="事项内容" prop="content">
                    <el-input v-model="form.content" type="textarea" placeholder="请输入内容" />
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>


    </div>
</template>

<script>
import request from '@/utils/request'
export default {
    name: 'Reminder',
    data() {
        return {
            // 双条件查询
            params: {
                title: '',
                pageNum: 1, //当前页码
                pageSize: 9 //一页多少数据
            },
            //表单主体内容
            tableData: [],
            //分页：总记录数
            total: 0,
            dialogFormVisible: false,
            //表单属性
            form: {},
            //颜色
            themeList: [
                {
                    value: 'blue',
                    label: '蓝色'
                },
                {
                    value: 'green',
                    label: '绿色'
                },
                {
                    value: 'red',
                    label: '红色'
                },
                {
                    value: 'yellow',
                    label: '黄色'
                },
                {
                    value: 'purple',
                    label: '紫色'
                },
                {
                    value: 'cyan',
                    label: '青色'
                },
                {
                    value: 'grey',
                    label: '灰色'
                }
            ],// 表单校验
            rules: {
                title: [
                    { required: true, message: "标题不能为空", trigger: "blur" }
                ],
                start: [
                    { required: true, message: "日期不能为空", trigger: "blur" }
                ],
                end: [
                    { required: true, message: "日期不能为空", trigger: "blur" }
                ],
                content: [
                    { required: true, message: "内容不能为空", trigger: "blur" }
                ],
                theme: [
                    { required: true, message: "内容不能为空", trigger: "blur" }
                ],
            },
        }
    },
    //一进入页面，就执行的代码
    created() {
        this.findBySearch()
    },
    methods: {
        findBySearch() {
            request.get("/reminder/page", { params: this.params }).then(res => {
                if (res.code === '200') {
                    this.total = res.data.total //初始化的时候，查询总信息数
                    this.tableData = res.data.records
                } else {
                    this.$message.error(res.msg);
                    console.log(' NOT Msg ·····')
                }
            })
        },
        // 刷新输入框
        refresh() {
            this.params.title = ''
            this.params.pageNum = 1
            this.params.pageSize = 9
            this.findBySearch()
        },
        // 添加用户的按钮
        addButton() {
            this.form = {},
                this.dialogFormVisible = true
        },
        //把表单的数据传输给后端（实际功能）
        submitForm() {
            this.$refs["form"].validate(valid => {
                if (valid) {
                    this.dialogFormVisible = false
                    request.post("/reminder", this.form).then(res => {
                        if (res.code === '200') {
                            this.findBySearch() //刷新页面
                            this.$message.success("操作成功，记得及时完成哦");
                        } else {
                            console.log(' NOT Msg ·····')
                            this.$message.error(res.msg);
                        }
                    })
                }
            });
        },
        // 修改数据
        edit(row) {
            this.dialogFormVisible = true
            this.form = row  //把对应行数的数据，交给了row，row再一一对应赋值
        },
        //删除数据
        deleteReminder(id) {
            // console.log(id);
            request.delete("/reminder/" + id).then(res => {
                if (res.code === '200') {
                    this.findBySearch() //刷新页面
                    this.$message.success("删除成功");
                } else {
                    console.log(' NOT Msg ·····')
                    this.$message.error(res.msg);
                }
            })
        },
        //打开可视化页面
        openView() {
            this.$router.push('/frontDesk/reminderView');
        },
        //表单的取消 : 关闭，刷新页面
        cancel() {
            this.dialogFormVisible = false; //关闭表单
            this.refresh(); //刷新页面
        },
        //分页：确定一页几条数据
        handleSizeChange(pageSize) {
            this.params.pageSize = pageSize;
            this.findBySearch();
        },
        //分页：确定当前是第几页
        handleCurrentChange(pageNum) {
            this.params.pageNum = pageNum;
            this.findBySearch();
        }

    },
}
</script>

<style scoped>
.reminder-body{
    margin: 0 30px 30px 30px;
}
</style>