<template>
  <div class="edit-body">
    <div class="edit-box">
      <el-form label-width="80px">
        <el-form-item label="用户名称">
          <el-input v-model="userInfo.userName"></el-input>
        </el-form-item>
        <el-form-item label="用户性别" style="margin-top: 30px">
          <el-radio v-model="userInfo.userSex" label="男">男</el-radio>
          <el-radio v-model="userInfo.userSex" label="女">女</el-radio>
          <el-radio v-model="userInfo.userSex" label="未知">未知</el-radio>
        </el-form-item>
        <el-form-item label="用户年龄" style="margin-top: 30px">
          <el-input-number v-model="userInfo.userAge"  :min="1" :max="100"
            label="年龄(1-18岁)"></el-input-number>
        </el-form-item>
        <!-- 上传头像 -->
        <div class="upload-box" style="margin-top: 30px">
          <el-upload class="upload-demo" drag :action=uploadUrl :on-success="uploadSuccess" :on-remove="handleRemove"
            :file-list="fileList" :limit=1 list-type="picture" multiple>
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">头像上传<em>支持jpg/png，不超过300MB</em></div>
            <div class="el-upload__tip" slot="tip"></div>
          </el-upload>
        </div>
      </el-form>
      <div class="btn-box">
        <el-button type="info" style="margin-right:170px" @click="backHomePage()">返回按钮</el-button>
        <el-button type="success" @click="updateUserInfo()">确定按钮</el-button>
      </div>

    </div>

  </div>
</template>

<script>
import request from '@/utils/request'

export default {
  data() {
    return {
      userInfo: {
        userName: '',
        userSex: '',
        userAge: '',
        userImg: ''
      },
      form: {},
      fileList: [],//文件上传
      uploadUrl: this.$root.apiUrl.uploadUrl, //(common.js中配置)
      downloadUrl: this.$root.apiUrl.downloadFile, //(common.js中配置)
      fileType: '', //文件类型
    }
  },
  created() {
    var localStorageUserInfo = localStorage.getItem('userInfo') // 获取本地存储的用户信息
    this.userInfo = JSON.parse(localStorageUserInfo) // 解析本地存储的用户信息
    console.log(this.userInfo.userImg)
    this.fileList.push({ name: '点击右上角(删除)，再上传新头像', url: this.userInfo.userImg });

  },
  methods: {
    updateUserInfo() {
      this.userInfo.userImg = this.form.fileUrl; // 将上传的文件路径赋值给用户信息的userImg属性
      request.post('/user', this.userInfo).then(res => {
        if (res.code === '200') {
          localStorage.removeItem('userInfo'); // 删除本地存储的用户信息
          this.$router.push('/login') // 跳转到登录页面
          this.$message.success('修改成功')
        } else {
          this.$message.error(res.msg);
        }
      })
    }, // 保存用户信息
    backHomePage() {
      this.$router.push('/frontDesk/homePage') // 返回用户列表页面
    },
    //移除文件上传的文件
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    //上传成功的回调
    uploadSuccess(response, file, fileList) {
      this.form.fileUrl = response.data.file_url;
      this.form.filePath = response.data.file_path;
      this.form.fileSize = response.data.file_size;
      this.fileType = response.data.file_type;
    }
  }
}
</script>

<style scoped>
.edit-body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  /* //这里地址是用项目中图片所在路径为准 */
  background: url("@/assets/back-tu.jpg");
  /* //将图片样式不重复 */
  background-repeat: no-repeat;
  /* //设置图片大小 */
  background-size: 100%;
  /* 设置全屏 */
  position: fixed;
  /* 设置div高度 */
  height: 100%;
  /* 设置div宽度 */
  width: 100%;
}

.edit-box {

  padding: 20px;
  margin-top: -200px;
  width: 25%;
  background-color: white;
  border-radius: 10px;
  /* 所有角都应用10px的弧度 */

}

.btn-box {
  margin-top: 30px;
}
</style>