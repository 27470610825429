<template>
  <div>
    <!-- 这个是input输入框 -->
    <div style="margin:5px 0 18px 0;">

      <el-button @click="newPage()" type="info" icon="el-icon-refresh-right" size="small" style="margin-right: 10px"
        circle></el-button>

      <el-input v-model="params.fileName" size="small" style="width: 200px; margin-right: 10px" placeholder="请输入文件名称">
      </el-input>

      <el-select v-model="params.fileSortId" filterable placeholder="请选择文件夹" size="small"
        style="width: 200px; margin-right: 10px">
        <el-option v-for="item in folderOptions" :key="item.id" :label="item.fileSortName" :value="item.id">
        </el-option>
      </el-select>


      <el-button type="warning" size="small" style="margin-left:3px" @click="findBySearch()">搜索</el-button>
      <el-button type="primary" size="small" @click="addButton()" style="margin-left: 15px">上传</el-button>
      <el-popconfirm title="确定删除吗？" @confirm="AlldeleteInfo()">
        <el-button slot="reference" size="small" type="danger" style="margin-left: 15px">批量删除</el-button>
      </el-popconfirm>

    </div>


    <!-- 这个是主体内容 -->
    <div>
      <h1>文件信息</h1>
      <el-table :data="tableData" style="width: 100%" ref="table" @selection-change="handleSelectionChange"
        :row-key="getRowKeys">

        <el-table-column ref="table" :reserve-selection="true" type="selection" width="55">
        </el-table-column>

        <el-table-column fixed prop="fileName" label="文件名称" width="150px"></el-table-column>

        <el-table-column prop="fileSize" label="文件大小(KB)" width="120px"></el-table-column>

        <el-table-column prop="fileUrl" label="图传分享" width="430px"></el-table-column>
        
        <el-table-column prop="filePath" label="磁盘路径" width="430px"></el-table-column>

        <el-table-column prop="createTime" label="上传时间" width="200px"></el-table-column>

        <el-table-column prop="fileSortName" label="文件夹"></el-table-column>

        <el-table-column label="下载" width="100px">
          <template slot-scope="scope">
            {{ scope.row.fileDownNumber }} 次
          </template>
        </el-table-column>


        <el-table-column fixed="right" label="操作" width="150px">
          <template slot-scope="scope">

            <!-- 修改文件 -->
            <el-button type="text" size="small" @click="updataFile(scope.row)">修改</el-button>

            <!-- 预览文件 -->
            <el-button type="text" size="small" @click="previewFile(scope.row)">预览</el-button>

            <!-- 下载文件-->
            <el-button type="text" size="small" @click="downloadFile(scope.row)" style="margin-left: 8px">下载</el-button>

            <!-- 删除文件 -->
            <el-popconfirm title="确定删除吗？" @confirm="deleteFile(scope.row.id)">
              <el-button slot="reference" type="text" size="small" style="margin-left: 8px">删除</el-button>
            </el-popconfirm>

          </template>
        </el-table-column>

      </el-table>
    </div>

    <!-- 分页条 -->
    <div style="text-align: center; margin: auto; margin-top: 50px;">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="params.pageNum" :page-sizes="[5, 10, 15, 20]" :page-size="params.pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>

    <!-- 弹框 -->
    <div>
      <el-dialog title="文件信息" :visible.sync="dialogFormVisible" width="40%">
        <el-form :model="form" :rules="rules" ref="form" class="demo-ruleForm">

          <el-form-item label="文件名称" label-width="20%" prop="fileName">
            <el-input :disabled="canEdit" v-model="form.fileName" autocomplete="off" style="width: 90%;"></el-input>
          </el-form-item>

          <el-form-item label="文件夹" label-width="20%" prop="folder">
            <el-select v-model="form.fileSortId" filterable placeholder="请选择文件夹">
              <el-option v-for="item in folderOptions" :key="item.id" :label="item.fileSortName" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="文件内容" label-width="20%">
            <el-upload class="upload-demo" drag :action=uploadUrl :on-success="uploadSuccess" :on-remove="handleRemove"
              :file-list="fileList" :limit=1 list-type="picture" multiple>
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip">支持txt、pdf、doc、docx、xlsx、jpg、png、zip文件，不超过300MB</div>
            </el-upload>
          </el-form-item>
        </el-form>

        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="addFile">确 定</el-button>
        </div>

      </el-dialog>
    </div>

  </div>
</template>

<script>
import request from '@/utils/request'
import { Base64 } from "js-base64";
export default {
  name: 'File',
  data() {
    return {
      // 双条件查询
      params: {
        fileName: "", //文件名称
        fileSortId: "", //文件夹id
        pageNum: 1, //当前页码
        pageSize: 5 //一页多少数据
      },
      //表单主体内容
      tableData: [],
      //分页：总记录数
      total: 0,
      dialogFormVisible: false,
      //表单属性
      form: {
      },
      canEdit: false, //编辑的时候，不能修改的 作品
      multipleSelection: [], //批量删除的选框
      // user: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {},
      rules: {
        fileName: [
          { required: true, message: '请输入文件名称', trigger: 'blur' },
        ]
      },
      fileList: [],//文件上传
      uploadUrl: this.$root.apiUrl.uploadUrl, //(common.js中配置)
      downloadUrl: this.$root.apiUrl.downloadFile, //(common.js中配置)
      previewUrl: this.$root.apiUrl.previewUrl, //(common.js中配置)
      fileType: '', //文件类型
      folderOptions: [], //文件夹选项
    }
  },
  //一进入页面，就执行的代码
  created() {
    this.findBySearch();
    this.folderSearch();
  },
  methods: {
    // 加载页面，模糊查询（调用后端接口，给后端传输数据）, 使用 {params: this.params} 给后端传输数据
    findBySearch() {
      request.get("/fileInfo/page", {
        params: this.params
      }).then(res => {
        if (res.code === '200') {
          this.total = res.data.total //初始化的时候，查询总信息数
          this.tableData = res.data.records
        } else {
          console.log(' NOT Msg ·····')
          this.$message.error(res.msg);
        }
      })
    },
    // 文件夹查询
    async folderSearch() {
      try {
        const response = await request.get("/folder", {
          params: this.params
        });
        if (response.code === '200') {
          this.folderOptions = response.data;
        } else {
          this.$message.error(response.msg);
          console.log(' NOT Msg ·····');
        }
      } catch (error) {
        console.error('Error fetching folder options:', error);
      }
    },
    // 刷新输入框
    newPage() {
      this.params.fileSortId = '';
      this.params.fileName = '';
      this.params.pageNum = 1;
      this.params.pageSize = 5;
      this.findBySearch();
    },
    // 添加文件的按钮
    addButton() {
      this.canEdit = false; // 编辑的时候，可以修改的 “文件名称”
      this.form = {};
      this.dialogFormVisible = true;
      this.fileList = [];
      this.fileType = ''; //清空文件类型
      this.folderSearch(); //查询文件夹选项
    },
    //把表单的数据传输给后端（实际功能）
    addFile() {
      if (this.form.fileSortId === '' || this.form.fileSortId === undefined) {
        this.$message.error("请选择文件夹！！！");
        return;
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.dialogFormVisible = false
          this.form.fileName = this.form.fileName + this.fileType; //给文件名加上文件类型
          request.post("/fileInfo/", this.form).then(res => {
            if (res.code === '200') {
              this.findBySearch() //刷新页面
              this.$message.success("添加成功");
              this.fileList = []; //清空上传的文件
              this.fileType = ''; //清空文件类型
            } else {
              console.log(' NOT Msg ·····')
              this.$message.error(res.msg);
              this.fileList = []; //清空上传的文件
              this.fileType = ''; //清空文件类型
            }
          })

        } else {
          this.$message.error("你没有按照规定输入");
          return false;
        }
      });
    },
    //下载文件
    downloadFile(row) {
      var filePath = row.filePath;
      var fileName = row.fileName;
      var url = this.downloadUrl + "?filePath=" + filePath + "&fileName=" + fileName;
      console.log(url)
      // 创建一个a标签，a标签的href是url
      const a = document.createElement("a");
      a.setAttribute("download", fileName);
      a.setAttribute("target", "_blank");
      a.setAttribute("href", url);
      a.click();

      // 设置一个定时器，延迟一段时间后刷新页面
      setTimeout(() => {
        this.findBySearch(); // 调用刷新页面的方法
      }, 3000); // 延迟时间设置为3000毫秒，根据实际情况调整
    },
    //预览文件
    previewFile(row) {
      var fileUrl = row.fileUrl;
      // console.log('--> ',fileUrl)
      // console.log('--base64--> ',Base64.encode(fileUrl))
      window.open(this.previewUrl + encodeURIComponent(Base64.encode(fileUrl)));
    },

    // 修改数据
    async updataFile(row) {
      this.canEdit = true; // 编辑的时候，不能修改的 “文件名称”
      this.dialogFormVisible = true;
      this.form = row; // 把对应行数的数据，交给了row，row再一一对应赋值
      this.fileList = []; // 清空上传的文件
      console.log(this.form)
      // 定义支持的图片格式
      const imageTypes = ['jpg', 'png', 'jpeg'];
      const fileType = row.fileName.split('.').pop().toLowerCase(); // 提取文件扩展名并转换为小写
      if (!imageTypes.includes(fileType)) {
        this.fileList.push({
          name: "该文件展示无法预览查看（点击右上角-删除）后继续上传文件",
          url: row.fileUrl,
        });
      } else {
        this.fileList.push({
          name: row.fileName,
          url: row.fileUrl,
        });
      }
      await this.folderSearch(); // 等待查询文件夹选项完成
    },
    //删除数据
    deleteFile(id) {
      request.delete("/fileInfo/" + id).then(res => {
        if (res.code === '200') {
          this.findBySearch() //刷新页面
          this.$message.success("删除成功");
        } else {
          console.log(' NOT Msg ·····')
          this.$message.error('操作失败');
        }
      })
    },
    //批量删除
    AlldeleteInfo() {
      //判断 用户 是否有选择框
      if (this.multipleSelection.length === 0) {
        this.$message.error('请选择需要删除的数据');
        return;
      }
      request.post("/fileInfo/deleteAll/", this.multipleSelection).then(res => {
        if (res.code === '200') {
          this.findBySearch() //刷新页面
          this.$message.success("删除成功")
        } else {
          console.log(' NOT Msg ·····')
          this.$message.error('删除操作失败');
        }
      })
    },
    //分页：确定一页几条数据
    handleSizeChange(pageSize) {
      this.params.pageSize = pageSize;
      this.findBySearch();
    },
    //分页：确定当前是第几页
    handleCurrentChange(pageNum) {
      this.params.pageNum = pageNum;
      this.findBySearch();
    },
    //批量删除的选框
    handleSelectionChange(val) {
      //点击这个框，会把 ‘对应一整条数’ 获取
      console.log(this.multipleSelection)
      this.multipleSelection = val;
    },
    //解决批量删除的，分页批量删除
    getRowKeys(row) {
      return row.id;
    },
    //移除文件上传的文件
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    //上传成功的回调
    uploadSuccess(response, file, fileList) {
      // console.log('response', response.data);
      this.form.fileUrl = response.data.file_url;
      this.form.filePath = response.data.file_path;
      this.form.fileSize = response.data.file_size;
      this.fileType = response.data.file_type;
    }

  },
}
</script>

<style></style>