<template>
    <div>
        <!-- 弹框 -->
        <div>
            <el-dialog title="文件信息" v-if="dialogFormVisible" :visible.sync="dialogFormVisible" width="40%"
                append-to-body @close="onClose">
                <el-form :model="form" :rules="rules" ref="form" class="demo-ruleForm">

                    <el-form-item label="文件名称" label-width="20%" prop="fileName">
                        <el-input :disabled="canEdit" v-model="form.fileName" autocomplete="off"
                            style="width: 90%;"></el-input>
                    </el-form-item>

                    <el-form-item label="文件夹" label-width="20%" prop="folder">
                        <el-select v-model="form.fileSortId" filterable placeholder="请选择文件夹">
                            <el-option v-for="item in folderOptions" :key="item.id" :label="item.fileSortName"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="文件内容" label-width="20%">
                        <el-upload class="upload-demo" drag :action=uploadUrl :on-success="uploadSuccess"
                            :on-remove="handleRemove" :file-list="fileList" :limit=1 multiple>
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                            <div class="el-upload__tip" slot="tip">支持txt、pdf、doc、docx、xlsx、jpg、png、zip文件，不超过300MB</div>
                        </el-upload>
                    </el-form-item>
                </el-form>

                <div slot="footer" class="dialog-footer">
                    <el-button @click="cancelUpload()">取 消</el-button>
                    <el-button type="primary" @click="addFile">确 定</el-button>
                </div>

            </el-dialog>
        </div>

    </div>
</template>

<script>
import request from '@/utils/request'
import { Base64 } from "js-base64";
export default {
    name: 'File',
    data() {
        return {
            // 双条件查询
            params: {
                fileName: "", //文件名称
                fileSortId: "", //文件夹id
                pageNum: 1, //当前页码
                pageSize: 5 //一页多少数据
            },
            //表单主体内容
            tableData: [],
            //分页：总记录数
            total: 0,
            dialogFormVisible: true,
            //表单属性
            form: {
            },
            canEdit: false, //编辑的时候，不能修改的 作品
            multipleSelection: [], //批量删除的选框
            // user: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {},
            rules: {
                fileName: [
                    { required: true, message: '请输入文件名称', trigger: 'blur' },
                ]
            },
            fileList: [],//文件上传
            uploadUrl: this.$root.apiUrl.uploadUrl, //(common.js中配置)
            downloadUrl: this.$root.apiUrl.downloadFile, //(common.js中配置)
            previewUrl: this.$root.apiUrl.previewUrl, //(common.js中配置)
            fileType: '', //文件类型
            folderOptions: [], //文件夹选项
        }
    },
    //一进入页面，就执行的代码
    created() {
        // this.findBySearch();
        this.folderSearch();
    },
    methods: {
        // 弹框取消（点击取消按钮）
        cancelUpload() {
            this.dialogFormVisible = false;
            this.$emit('close-dialog');
        },
        // 弹框关闭(点击空白)
        onClose() {
            // 触发自定义事件
            this.$emit('close-dialog');
        },
        // 加载页面，模糊查询（调用后端接口，给后端传输数据）, 使用 {params: this.params} 给后端传输数据
        findBySearch() {
            request.get("/fileInfo/page", {
                params: this.params
            }).then(res => {
                if (res.code === '200') {
                    this.total = res.data.total //初始化的时候，查询总信息数
                    this.tableData = res.data.records
                } else {
                    console.log(' NOT Msg ·····')
                    this.$message.error(res.msg);
                }
            })
        },
        // 文件夹查询
        async folderSearch() {
            try {
                const response = await request.get("/folder", {
                    params: this.params
                });
                if (response.code === '200') {
                    this.folderOptions = response.data;
                } else {
                    this.$message.error(response.msg);
                    console.log(' NOT Msg ·····');
                }
            } catch (error) {
                console.error('Error fetching folder options:', error);
            }
        },
        // 刷新输入框
        newPage() {
            this.params.fileSortId = '';
            this.params.fileName = '';
            this.params.pageNum = 1;
            this.params.pageSize = 5;
            this.findBySearch();
        },
        //把表单的数据传输给后端（实际功能）
        addFile() {
            if (this.form.fileSortId === '' || this.form.fileSortId === undefined) {
                this.$message.error("请选择文件夹！！！");
                return;
            }
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.dialogFormVisible = false
                    this.form.fileName = this.form.fileName + this.fileType; //给文件名加上文件类型
                    request.post("/fileInfo/", this.form).then(res => {
                        if (res.code === '200') {
                            this.findBySearch() //刷新页面
                            this.$message.success("添加成功");
                            this.fileList = []; //清空上传的文件
                            this.fileType = ''; //清空文件类型
                            this.$emit('close-dialog');
                        } else {
                            console.log(' NOT Msg ·····')
                            this.$message.error(res.msg);
                            this.fileList = []; //清空上传的文件
                            this.fileType = ''; //清空文件类型
                        }
                    })

                } else {
                    this.$message.error("你没有按照规定输入");
                    return false;
                }
            });
        },
        //下载文件
        downloadFile(row) {
            var filePath = row.filePath;
            var fileName = row.fileName;
            var url = this.downloadUrl + "?filePath=" + filePath + "&fileName=" + fileName;
            console.log(url)
            // 创建一个a标签，a标签的href是url
            const a = document.createElement("a");
            a.setAttribute("download", fileName);
            a.setAttribute("target", "_blank");
            a.setAttribute("href", url);
            a.click();

            // 设置一个定时器，延迟一段时间后刷新页面
            setTimeout(() => {
                this.findBySearch(); // 调用刷新页面的方法
            }, 3000); // 延迟时间设置为3000毫秒，根据实际情况调整
        },
        //预览文件
        previewFile(row) {
            var fileUrl = row.fileUrl;
            window.open(this.previewUrl + encodeURIComponent(Base64.encode(fileUrl)));
        },
        //移除文件上传的文件
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        //上传成功的回调
        uploadSuccess(response, file, fileList) {
            // console.log('response', response.data);
            this.form.fileUrl = response.data.file_url;
            this.form.filePath = response.data.file_path;
            this.form.fileSize = response.data.file_size;
            this.fileType = response.data.file_type;
        }

    },
}
</script>

<style></style>