<template>
    <div class="front-layout">
        <el-container>

            <!-- 头部 -->
            <el-header>
                <div class="header-content" style="display: flex; justify-content: space-between; align-items: center;">
                    <!-- 导航菜单 -->
                    <el-menu :default-active="activeIndex" router class="el-menu-demo" mode="horizontal"
                        @select="handleSelect">
                        <el-menu-item index="/frontDesk/homePage">首页</el-menu-item>
                        <el-menu-item index="/frontDesk/folderPage">文件夹</el-menu-item>
                        <el-menu-item index="/frontDesk/reminderView">提醒事项</el-menu-item>
                    </el-menu>
                    <!-- 头像 -->
                    <div class="avatar-container" @click="editUserInfo()" @contextmenu.prevent="handleRightClick()">
                        <el-avatar :size="50" :src="userInfo.userImg"></el-avatar>
                    </div>
                    <div>
                        <el-switch v-model="onlineStatus" active-text="登入/退出" inactive-color="#ff4949"
                            @change="handleOnlineStatus">
                        </el-switch>
                    </div>
                </div>
            </el-header>


            <!-- 主体 -->
            <el-main>
                <router-view></router-view>
            </el-main>

        </el-container>
    </div>
</template>

<script>
export default {
    data() {
        return {
            activeIndex: '/frontDesk/homePage', // 当前激活的菜单项
            userInfo: {
            },
            onlineStatus: true // 在线状态
        };
    },
    created() {
        // 当组件创建时，设置 activeIndex 为当前路由的 path
        this.activeIndex = this.$route.path;
        var localStorageUserInfo = localStorage.getItem('userInfo') // 获取本地存储的用户信息
        this.userInfo = JSON.parse(localStorageUserInfo) // 解析本地存储的用户信息
    },
    watch: {
        '$route'(to, from) {
            // 监听路由变化，更新 activeIndex
            this.activeIndex = to.path;
        }
    },
    methods: {
        editUserInfo() {
            this.$router.push('/editUser')
        },
        handleOnlineStatus(state) {
            if (!state) {
                this.$router.replace('/login');
                this.$message.success('退出成功')
            }
        },
        handleRightClick() {
            this.$router.push('/admin/homeView')
        }
    }
}
</script>

<style scoped>
.avatar-container {
    cursor: pointer;
    margin-top: 12px;
    margin-left: auto;
    /* 将头像推到最右边 */
    margin-right: 10px;
    /* 头像右侧的间隔 */
}
</style>