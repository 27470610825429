/**
 * 配置： 项目通用的URL
 */
const apiUrls = {
  //服务器环境
  downloadFile: "http://159.138.43.80:8099/chenxixi/file/document/download", //下载文件
  uploadUrl: "http://159.138.43.80:8099/chenxixi/file/document/upload", //上传文件
  previewUrl: "http://159.138.43.80:8012/onlinePreview?url=", //预览文件(kkfileview)

  //本地环境
  // downloadFile: "http://localhost:8099/file/document/download", //下载文件
  // uploadUrl: "http://localhost:8099/file/document/upload", //上传文件
};

export default apiUrls;
