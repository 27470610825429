import axios from "axios";

// 创建一个axios对象出来
const request = axios.create({
  // 和后端配置的port相呼应 + 后端配置的 WebConfig

  //2：本地
  // baseURL: 'http://localhost:8099/chenxixi',
  baseURL: 'http://159.138.43.80:8099/chenxixi',
  timeout: 6000,
});

const ongoingRequests = new Map();
let clickCooldown = false;

// Request interceptors
request.interceptors.request.use(
  (config) => {
    if (clickCooldown) {
      return Promise.reject(new axios.Cancel("操作频繁，请稍后再试"));
    }

    config.headers["Content-Type"] = "application/json;charset=utf-8";

    //获取token，并添加到请求头
    const user = localStorage.getItem("userInfo");
    if (user) {
      config.headers["token"] = JSON.parse(user).token;
    }

    const requestKey = `${config.method}-${config.url}`;

    if (ongoingRequests.has(requestKey)) {
      clickCooldown = true;
      setTimeout(() => {
        clickCooldown = false;
      }, 1000); // 设置冷却时间（毫秒）

      return Promise.reject(new axios.Cancel("操作频繁，请稍后再试"));
    }

    const cancelTokenSource = axios.CancelToken.source();
    config.cancelToken = cancelTokenSource.token;

    ongoingRequests.set(requestKey, cancelTokenSource);

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptors
request.interceptors.response.use(
  (response) => {
    let res = response.data;
    if (typeof res === "string") {
      res = res ? JSON.parse(res) : res;
    }

    const requestKey = `${response.config.method}-${response.config.url}`;
    ongoingRequests.delete(requestKey);

    return res;
  },
  (error) => {
    console.log("err" + error);

    const requestKey = `${error.config.method}-${error.config.url}`;
    ongoingRequests.delete(requestKey);

    return Promise.reject(error);
  }
);

export default request;
