import Vue from "vue";
import VueRouter from "vue-router";
import LayoutView from "../views/Layout.vue";
import HomeView from "@/views/HomeView";
import Folder from "@/views/folder";
import Login from "@/views/login";
import File from "@/views/file";
import FrontDesk from "@/viewFrontDesk/FrontLayoutView.vue"; //前端管理页面
import HomePage from "@/viewFrontDesk/homePage"; //前端首页
import FolderPage from "@/viewFrontDesk/folderPage"; //前端文件夹页
import Reminder from "@/viewFrontDesk/reminderPage/Reminder.vue"; //提醒事项
import ReminderView from "@/viewFrontDesk/reminderPage/ReminderView.vue"; //提醒事项(可视化页面)
import EditUser from "@/views/editUser/index.vue";

Vue.use(VueRouter);

const routes = [
  //登入页面
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  //编辑用户页面
  {
    path: "/editUser",
    name: "editUser",
    component: EditUser,
  },
  //后台管理页面
  {
    path: "/admin",
    name: "layoutView",
    component: LayoutView,
    children: [
      {
        path: "homeView",
        name: "homeView",
        component: HomeView,
      },
      {
        path: "folder",
        name: "folder",
        component: Folder,
      },
      {
        path: "file",
        name: "file",
        component: File,
      },
    ],
  },
  //前台页面
  {
    path: "/frontDesk",
    name: "frontLayoutView",
    component: FrontDesk,
    children: [
      {
        path: "homePage",
        name: "homePage",
        component: HomePage,
      },
      {
        path: "folderPage",
        name: "folderPage",
        component: FolderPage,
      },
      {
        path: "reminder",
        name: "reminder",
        component: Reminder,
      },
      {
        path: "reminderView",
        name: "reminderView",
        component: ReminderView,
      },
    ],
  },
  // 根路径重定向到登录页面
  {
    path: "/",
    redirect: "/login",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
