<template>
    <div class="home-body">

        <!-- Banner图 -->
        <!-- <div style="height: 343px; margin-bottom: 50px;">
            <el-carousel arrow="always" height="380px" :interval="6000" :autoplay="true">
                <el-carousel-item v-for="item in imgList" :key="item.id">
                    <img style="margin: 0%;  width: 100%; height: 340px; border-radius: 50px;" class="medium"
                        :src="item.idView">
                </el-carousel-item>
            </el-carousel>
        </div> -->
        <!-- 功能介绍 -->
        <div class="container">
            <el-row>
                <!-- 右边 -->
                <el-col :span="12">
                    <div class="left-box">
                        <el-card class="box-card">
                            <div slot="header" class="clearfix">
                                <span>数据统计</span>
                            </div>
                            <div class="statistics-list">
                                <div class="statistic-item">
                                    <span class="label">文件夹总数：</span>
                                    <span class="value">{{ fileAndFolderTotalInfo.totalFolder }}</span>
                                </div>
                                <div class="statistic-item">
                                    <span class="label">文件总数：</span>
                                    <span class="value">{{ fileAndFolderTotalInfo.totalFiles }}</span>
                                </div>
                                <div class="statistic-item">
                                    <span class="label">总文件大小：</span>
                                    <span class="value">{{ fileAndFolderTotalInfo.fileSizeFormat }}</span>
                                </div>
                                <div class="statistic-item">
                                    <span class="label">总文件下载次数：</span>
                                    <span class="value">{{ fileAndFolderTotalInfo.fileDownNumber }}</span>
                                </div>
                                <div class="statistic-item">
                                    <span class="label">磁盘剩余内存：</span>
                                    <span class="value">{{ fileAndFolderTotalInfo.systemInfo.freeMemoryFormat }}</span>
                                </div>
                            </div>
                        </el-card>
                    </div>
                </el-col>
                <!-- 左边 -->
                <el-col :span="12">
                    <div class="light-box">
                        <el-card class="box-card">
                            <div slot="header" class="clearfix">
                                <span>本机数据</span>
                            </div>
                            <div class="statistics-list">
                                <div class="statistic-item">
                                    <span class="label">操作系统名称：</span>
                                    <span class="value">{{ fileAndFolderTotalInfo.systemInfo.osName }}</span>
                                </div>
                                <div class="statistic-item">
                                    <span class="label">操作系统版本：</span>
                                    <span class="value">{{ fileAndFolderTotalInfo.systemInfo.osVersion }}</span>
                                </div>
                                <div class="statistic-item">
                                    <span class="label">Java 版本：</span>
                                    <span class="value">{{ fileAndFolderTotalInfo.systemInfo.jdkVersion }}</span>
                                </div>
                                <div class="statistic-item">
                                    <span class="label">用户目录：</span>
                                    <span class="value">{{ fileAndFolderTotalInfo.systemInfo.userHome }}</span>
                                </div>
                                <div class="statistic-item">
                                    <span class="label">当前目录：</span>
                                    <span class="value">{{ fileAndFolderTotalInfo.systemInfo.userDir }}</span>
                                </div>
                            </div>
                        </el-card>
                    </div>
                </el-col>

            </el-row>
        </div>

        <!-- 图表展示 -->
        <div class="bar-box">
            <div style="width: 90%">
                <div ref="barChart" style="width: 100%; height: 500px"></div>
            </div>
        </div>

    </div>
</template>

<script>
import request from '@/utils/request'
import * as echarts from "echarts";
export default {
    data() {
        return {
            // 轮播图列表
            // imgList: [
            //     { id: 0, idView: require("@/assets/banner/01.jpg") },
            // ],
            fileAndFolderTotalInfo: [],//查询文件和文件夹的汇总信息
            folderCorrespondenceFileNumberMap: {},//查询文件夹对应文件数量

        }
    },
    mounted() {
    },
    created() {
        this.SearchFileAndFolderTotalInfo(); //（数据统计的数据）
        setTimeout(() => {
            this.SearchfolderCorrespondenceFileNumber(); //（柱状图的数据）
        }, 1000);
    },
    methods: {
        //查询（右边-数据统计的数据）
        SearchFileAndFolderTotalInfo() {
            request.get("/fileInfo/fileAndFolderTotalInfo").then(res => {
                if (res.code === '200') {
                    this.fileAndFolderTotalInfo = res.data
                } else {
                    this.$message.error(res.msg);
                    console.log(' NOT Msg ·····')
                }
            })
        },
        //文件夹对应的文件数量
        SearchfolderCorrespondenceFileNumber() {
            request.get("/folder/folderCorrespondenceFileNumber").then(res => {
                if (res.code === '200') {
                    this.$nextTick(() => {
                        this.horizontalBarChart(res.data.data_x, res.data.data_y)
                    })
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        //初始化图表
        initChart() {
            const chartDom = this.$refs.barChart;
            if (chartDom) {
                this.chart = echarts.init(chartDom);
                this.horizontalBarChart();
            } else {
                console.error('Chart DOM not found1111');
            }
        },
        //柱状图
        horizontalBarChart(xAxisData, yAxisData) {
            this.$nextTick(() => { // 确保DOM已更新
                var chartDom = this.$refs.barChart;
                if (chartDom) {
                    var myChart = echarts.init(chartDom);
                    var option;
                    option = {
                        title: {
                            text: "文件夹数据",
                        },
                        tooltip: {
                            trigger: "axis",
                            axisPointer: {
                                type: "shadow",
                            },
                        },
                        legend: {},
                        grid: {
                            left: "3%",
                            right: "4%",
                            bottom: "3%",
                            containLabel: true,
                        },
                        xAxis: {
                            type: "value",
                            boundaryGap: [0, 0.01],
                        },
                        yAxis: {
                            type: "category",
                            data: yAxisData,
                        },
                        series: [
                            {
                                name: "文件数量",
                                type: "bar",
                                data: xAxisData,
                            },
                        ],
                    };
                    option && myChart.setOption(option);
                } else {
                    console.error('Chart DOM not found');
                }
            })

        },
    },
    destroyed() {
        this.chart.dispose(); // 销毁图表
        // window.location.reload(); // 刷新页面
    },
}
</script>

<style scoped>
.home-body {
    /* border: 1px solid #eee; */
    width: 80%;
    margin: auto;
}

/* 右边 */
.light-box {
    margin-left: 0px;
    width: 100%;
}

.text {
    font-size: 14px;
}

.item {
    margin-bottom: 18px;
}

.statistic-item {
    /* 统计项目样式，例如： */
    margin-bottom: 10px;
    /* 添加底部间距 */
    display: flex;
    /* 使用flex布局 */
    align-items: center;
    /* 垂直居中 */
}

.label {
    /* 标签样式，例如： */
    font-weight: bold;
    /* 加粗 */
    margin-right: 5px;
    /* 添加右侧间距 */
}

.value {
    /* 值样式，例如： */
    color: #409EFF;
    /* 更改颜色 */
}


/* 左边 */
.left-box {
    width: 90%;
}

/* 图表展示 */
.bar-box {
    margin-top: 50px;
}
</style>