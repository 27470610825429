import Vue from 'vue'
import App from './App.vue'
import router from './router'
import gloable from './assets/gloable.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import apiUrls from '@/utils/common';
import SvgIcon from '@/components/SvgIcon.vue';


Vue.config.productionTip = false
Vue.use(ElementUI);
// 全局注册svg-icon组件
Vue.component('svg-icon', SvgIcon);


new Vue({
  router,
  render: h => h(App),
  data: {
    apiUrl: apiUrls,
  },
  //全局事件总线
  beforeCreate() {
    Vue.prototype.$bus = new Vue();
  }
}).$mount('#app')