<template>
    <div>
        <!-- 这个是input输入框 -->
        <div style="margin:5px 0 18px 0;">

            <el-button @click="newPage()" type="info" icon="el-icon-refresh-right" size="small"
                style="margin-right: 10px" circle>

            </el-button> <el-input v-model="params.fileSortName" size="small" style="width: 200px; margin-right: 10px"
                placeholder="请输入文件夹名称">
            </el-input>


            <el-button type="warning" size="small" @click="findBySearch()">搜索</el-button>
            <el-button type="primary" size="small" @click="addButton()" style="margin-left: 15px">添加</el-button>
            <el-popconfirm title="确定删除吗？" @confirm="AlldeleteInfo()">
                <el-button slot="reference" size="small" type="danger" style="margin-left: 15px">批量删除</el-button>
            </el-popconfirm>

        </div>


        <!-- 这个是主体内容 -->
        <div>
            <h1>文件夹信息</h1>
            <el-table :data="tableData" style="width: 100%" ref="table" @selection-change="handleSelectionChange"
                :row-key="getRowKeys">

                <el-table-column ref="table" :reserve-selection="true" type="selection" width="55">
                </el-table-column>

                <el-table-column fixed prop="fileSortName" label="文件夹名称"></el-table-column>

                <el-table-column prop="creatTime" label="创建时间"></el-table-column>

                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" size="small" @click="edit(scope.row)">编辑</el-button>
                        <el-popconfirm title="确定删除吗？" @confirm="deleteInfo(scope.row.id)">
                            <el-button slot="reference" size="small" type="danger"
                                style="margin-left: 5px">删除</el-button>
                        </el-popconfirm>
                    </template>
                </el-table-column>

            </el-table>
        </div>

        <!-- 分页条 -->
        <div style="text-align: center; margin: 25px;">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="params.pageNum" :page-sizes="[5, 10, 15, 20]" :page-size="params.pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </div>

        <!-- 弹框 -->
        <div>
            <el-dialog title="添加文件夹" :visible.sync="dialogFormVisible" width="30%">
                <el-form :model="form">

                    <el-form-item label="文件夹名称" label-width="20%">
                        <el-input v-model="form.fileSortName" autocomplete="off" style="width: 90%;"></el-input>
                    </el-form-item>
                </el-form>

                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="addInfo">确 定</el-button>
                </div>

            </el-dialog>
        </div>

    </div>
</template>

<script>
import request from '@/utils/request'
export default {
    data() {
        return {
            // 双条件查询
            params: {
                fileSortName: '',
                pageNum: 1, //当前页码
                pageSize: 5 //一页多少数据
            },
            //表单主体内容
            tableData: [],
            //分页：总记录数
            total: 0,
            dialogFormVisible: false,
            //表单属性
            form: {
                fileSortName: '',
            },
            multipleSelection: [], //批量删除的选框
            user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {}
        }
    },
    //一进入页面，就执行的代码
    created() {
        this.findBySearch()
    },
    methods: {
        // 加载页面，模糊查询（调用后端接口，给后端传输数据）, 使用 {params: this.params} 给后端传输数据
        findBySearch() {
            request.get("/folder/page", {
                params: this.params
            }).then(res => {
                if (res.code === '200') {
                    this.total = res.data.total //初始化的时候，查询总信息数
                    this.tableData = res.data.records
                } else {
                    this.$message.error(res.msg);
                    console.log(' NOT Msg ·····')
                }
            })
        },
        // 刷新输入框
        newPage() {
            this.params.fileSortName = '';
            this.params.pageNum = 1;
            this.params.pageSize = 5;
            this.findBySearch();
        },
        // 添加用户的按钮
        addButton() {
            this.form = {};
            this.dialogFormVisible = true;
        },
        //把表单的数据传输给后端（实际功能）
        addInfo() {
            this.dialogFormVisible = false
            this.canEdit = false;
            request.post("/folder/", this.form).then(res => {
                if (res.code === '200') {
                    this.findBySearch() //刷新页面
                    this.$message.success("添加成功");
                } else {
                    console.log(' NOT Msg ·····')
                    this.$message.error(res.msg);
                }
            })
        },
        // 修改数据
        edit(row) {
            this.dialogFormVisible = true;
            this.form = row;  //把对应行数的数据，交给了row，row再一一对应赋值
        },
        //删除数据
        deleteInfo(id) {
            request.delete("/folder/" + id).then(res => {
                if (res.code === '200') {
                    this.findBySearch() //刷新页面
                    this.$message.success("删除成功");
                } else {
                    console.log(' NOT Msg ·····')
                    this.$message.error(res.msg);
                }
            })
        },
        //批量删除
        AlldeleteInfo() {
            //判断 用户 是否有选择框
            if (this.multipleSelection.length === 0) {
                this.$message.error('请选择需要删除的数据');
                return;
            }
            request.post("/folder/deleteAll/", this.multipleSelection).then(res => {

                if (res.code === '200') {
                    this.findBySearch(); //刷新页面
                    this.$message.success("批量删除成功");
                } else {
                    console.log(' NOT Msg ·····');
                    this.$message.error(res.msg);
                }
            })
        },
        //分页：确定一页几条数据
        handleSizeChange(pageSize) {
            this.params.pageSize = pageSize;
            this.findBySearch();
        },
        //分页：确定当前是第几页
        handleCurrentChange(pageNum) {
            this.params.pageNum = pageNum;
            this.findBySearch();
        },
        //批量删除的选框
        handleSelectionChange(val) {
            //点击这个框，会把 ‘对应一整条数’ 获取
            console.log(this.multipleSelection)
            this.multipleSelection = val;
        },
        //解决批量删除的，分页批量删除
        getRowKeys(row) {
            return row.id;
        }

    },
}
</script>

<style></style>