<template>
  <div class="svg-icon">
    <svg v-if="svgContent" v-html="svgContent"></svg>
  </div>
</template>

<script>
export default {
  name: 'SvgIcon',
  props: {
    name: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      svgContent: ''
    };
  },
  mounted() {
    this.loadSvg();
  },
  methods: {
    loadSvg() {
      const xhr = new XMLHttpRequest();
      const self = this;
      xhr.open('GET', require(`@/assets/icon/${this.name}.svg`), true);
      xhr.onreadystatechange = function() {
        if (xhr.readyState === 4 && xhr.status === 200) {
          self.svgContent = xhr.responseText;
        }
      };
      xhr.send();
    }
  }
}
</script>

<style scoped>
.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  /* 其他样式 */
}
</style>
