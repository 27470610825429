<template>
    <div class="body">
        <div class="content">
            <el-form :model="form" :rules="rules" ref="form">
                <!-- 手机号 -->
                <el-form-item prop="userPhone">
                    <el-input placeholder="请输入手机号" v-model="form.userPhone">
                        <template slot="prepend">手机</template>
                    </el-input>
                </el-form-item>
                <!-- 密码 -->
                <el-form-item prop="userPassword">
                    <el-input show-password placeholder="请输入密码" v-model="form.userPassword">
                        <template slot="prepend">密码</template>
                    </el-input>
                </el-form-item>
                <!-- 验证码 -->
                <el-form-item prop="captchaResult">
                    <div class="captcha">
                        <img @click="RefreshCaptcha()" :src="captchaBase64" alt="网络问题"
                            style="width: 300px; height: 40px;">
                        <el-input placeholder="请输入计算结果" v-model="form.captchaResult" clearable
                            style="margin-left: 10px;">
                        </el-input>
                    </div>
                </el-form-item>
                <!-- 登录按钮 -->
                <div class="button">
                    <el-button plain type="success" @click="login()" style="width: 100%;">登入</el-button>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
import request from '@/utils/request'

export default {
    data() {
        return {
            captchaBase64: '', //验证码图片
            form: {
                userPhone: '',
                userPassword: '',
                captchaResult: '',
                captchaKey: ''
            },
            rules: {
                userPhone: [
                    { required: true, message: '请输入手机号', trigger: 'blur' }
                ],
                userPassword: [
                    { required: true, message: '请输入密码', trigger: 'blur' }
                ],
                captchaResult: [
                    { required: true, message: '请输入计算结果', trigger: 'blur' }
                ]
            }
        }
    },
    //一进入页面，就执行的代码
    created() {
        this.findCaptcha()
    },
    methods: {
        //刷新验证码
        RefreshCaptcha() {
            this.findCaptcha();
        },
        //获取验证码
        findCaptcha() {
            request.get("/captchaMath",).then(res => {
                if (res.code === '200') {
                    // 存储captchaKey到本地存储
                    localStorage.setItem('captchaKey', res.data.key);
                    this.captchaBase64 = res.data.image
                } else {
                    console.log(' 验证码获取失败 ·····')
                    this.$message.error(res.msg);
                }
            })
        },
        //登入
        login() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    const captchaKey = localStorage.getItem('captchaKey'); // 从本地存储获取captchaKey
                    this.form.captchaKey = captchaKey
                    // 发送登录请求，包含captchaKey和userInputCaptcha
                    request.post("/login", this.form).then(res => {
                        if (res.code === '200') {
                            this.$message.success("登入成功");
                            localStorage.setItem('userInfo', JSON.stringify(res.data))
                            this.form = {}; //登入成功后，输入框init
                            this.$router.push('/frontDesk/homePage'); //跳转到首页
                        } else {
                            console.log(' 验证码获取失败 ·····')
                            this.$message.error(res.msg);
                        }
                    })
                } else {
                    // 如果验证失败，提示用户
                    this.$message.error('请完善表单信息');
                    return false;
                }
            });
        }
    }
}
</script>

<style scoped>
.body {
    /* //这里地址是用项目中图片所在路径为准 */
    background: url("@/assets/back-tu.jpg");
    /* //将图片样式不重复 */
    background-repeat: no-repeat;
    /* //设置图片大小 */
    background-size: 100%;
    /* 设置全屏 */
    position: fixed;
    /* 设置div高度 */
    height: 100%;
    /* 设置div宽度 */
    width: 100%;

}

/* 居中页面 */
.content {
    padding: 30px 30px 20px 30px;
    border: 1px silver;
    text-align: center;
    background-color: #fff;
    border-radius: 20px;
    width: 300px;
    height: 230px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

}

.el-select .el-input {
    width: 130px;
}

.input-with-select .el-input-group__prepend {
    background-color: #fff;
}

.button {
    margin-top: 20px;
}

.captcha {
    display: flex;
    margin-bottom: 0px;
}
</style>